if (document.getElementById("hero__swiper")) {
    const swiperHero = new Swiper("#hero__swiper", {
        effect: "fade",
        speed: 2000,
        lazy: true,
        loop: true,
        autoplay: {
            delay: 6000,
            speed: 3000,
            disableOnInteraction: false,
        },

        pagination: {
            el: "#hero__swiper .swiper-pagination",
            clickable: "true",
        },
        navigation: {
            nextEl: "#hero__swiper .swiper-button-next",
            prevEl: "#hero__swiper .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',
        on: {
            init: function () {
                document.querySelector("#hero__swiper .swiper-slide-active").classList.add("swiper-slide-active-transition");
            },
            resize: function () {
                document.querySelector("#hero__swiper .swiper-slide-active").classList.add("swiper-slide-active-transition");
            },
        },
    });
    swiperHero.on('slideChangeTransitionStart', function (swiper, transition) {

        let classN=document.querySelector("#hero__swiper .swiper-slide-active").getAttribute("data-class");
        document.getElementById("hero__swiper").setAttribute("data-class", classN);
        if (!document.querySelector("#hero__swiper .swiper-slide-active .hero__img").classList.contains("isloading")) {
            document.querySelector("#hero__swiper .swiper-slide-active .hero__img").classList.add("isloading");
            const img = document.querySelector("#hero__swiper .swiper-slide-active .hero__img").getAttribute("data-src");
            document.querySelector("#hero__swiper .swiper-slide-active .hero__img").setAttribute("src", img);
        }
        document.querySelector("#hero__swiper .swiper-slide-active").classList.add("swiper-slide-active-transition");
    });

    swiperHero.on('beforeTransitionStart', function (swiper) {
        if (document.querySelector("#hero__swiper .swiper-slide-active-transition")) {
            document.querySelector("#hero__swiper .swiper-slide-active-transition").classList.remove("swiper-slide-active-transition");
        }
    });
}

if (document.querySelectorAll(".so__swiper").length > 0) {
    var swiper = new Swiper(".so__swiper", {
        slidesPerView: 3,
        loop: true,
        spaceBetween: 5,
        navigation: {
            nextEl: ".so__swiper .swiper-button-next",
            prevEl: ".so__swiper .swiper-button-prev",
        },
        breakpoints: {
            576: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            992: {
                slidesPerView: 5,
                spaceBetween: 10,
            },
            1200: {
                slidesPerView: 7,
                spaceBetween: 20,
            },
        },
    });
}


if (document.querySelectorAll(".product__swiper").length > 0) {

    let swiperSliders=document.querySelectorAll(".product__swiper");

    for(let i=0; i<swiperSliders.length; i++){
        let swiperItem=swiperSliders[i];
        let prev=swiperItem.querySelector(".swiper-button-prev");
        let next=swiperItem.querySelector(".swiper-button-next");
        const swiperProduct = new Swiper(swiperItem, {
            loop: true,
            navigation: {
                nextEl: prev,
                prevEl: next,
            },
        });
        prev.addEventListener("click", function(e){
            e.stopPropagation();
        }) ;
        next.addEventListener("click", function(e){
            e.stopPropagation();
        })
    }

}

if (document.getElementById("opinion__swiper")) {
    const swiperOpinion = new Swiper("#opinion__swiper", {
        loop: true,
        // autoplay: {
        //     delay: 6000,
        //     speed: 3000,
        //     disableOnInteraction: false,
        // },

        pagination: {
            el: ".opinion__pagination",
            clickable: "true",
        },
        navigation: {
            nextEl: "#opinion__swiper .swiper-button-next",
            prevEl: "#opinion__swiper .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',

    });

}

if (document.querySelectorAll(".pr__swiper").length > 0) {
    const swiper = new Swiper(".pr__swiper", {
        slidesPerView: 1,
        loop: true,
        spaceBetween: 5,
        navigation: {
            nextEl: ".pr__swiper .swiper-button-next",
            prevEl: ".pr__swiper .swiper-button-prev",
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
                spaceBetween: 10,
            },

            1200: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });
}